/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import WorkCaseStudies from 'routes/work'

// Meta Component
import Meta from 'components/Meta/Meta'

// WorkCaseStudies Route
export default WorkCaseStudies

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.page.seoMetaTags.tags} />
}

export const query = graphql`
  query WorkCaseStudyPageQuery {
    page: datoCmsPage(slug: { eq: "work" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      excerpt
      featuredMedia {
        gatsbyImageData(
          placeholder: TRACED_SVG
          imgixParams: { w: "450", fm: "jpg", auto: "compress", maxW: 450 }
        )
      }
    }
    caseStudies: allDatoCmsCasestudy(
      limit: 8
      sort: { fields: position, order: ASC }
      filter: { isHidden: { eq: false } }
    ) {
      edges {
        node {
          id
          title
          slug
          heroBannerImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                w: "640"
                h: "480"
                fit: "crop"
                fm: "jpg"
                auto: "compress"
              }
            )
          }
          listingAppImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                w: "640"
                fit: "crop"
                fm: "png"
                auto: "compress"
              }
            )
          }
          cardAppImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                w: "1200"
                fit: "crop"
                fm: "png"
                auto: "compress"
              }
            )
          }
          outcomes {
            outcomeValue
            summary
          }
          client {
            companyName
            logo {
              url
              gatsbyImageData(
                placeholder: TRACED_SVG
                imgixParams: {
                  fm: "png"
                  auto: "compress"
                  maxW: 1024
                  bri: -20
                  h: "60"
                }
              )
            }
            logoLight {
              url
              gatsbyImageData(
                placeholder: TRACED_SVG
                layout: FIXED
                height: 35
                imgixParams: {
                  fm: "png"
                  auto: "compress"
                  monochrome: "000000"
                  maxH: 60
                  bri: -100
                }
              )
            }
          }
        }
      }
    }
    testimonials: allDatoCmsClientTestimonial(limit: 2) {
      edges {
        node {
          id
          clientName
          clientRole
          content
          clientCompany
          isFlipped
          caseStudy {
            slug
          }
          clientLogo {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: { fm: "jpg", auto: "compress" }
            )
          }
          clientImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: { fm: "jpg", auto: "compress" }
            )
          }
        }
      }
    }
  }
`
