import PropTypes from 'prop-types'
import React from 'react'
import { Section, Container } from 'react-bulma-components'

// Components
import Layout from 'components/layout'
import SiteNav from 'components/navbar'
import WorkNav from 'components/work-nav'
import ArrowLink from 'components/link-with-arrow'
import Testimonials from 'components/testimonials'
import WorkHero from 'components/workHero'

// Page Components
import AllWork from './components/all-work'

function WorkCaseStudies({ data: { caseStudies, page, testimonials } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <WorkNav />
      <WorkHero page={page} />
      <AllWork caseStudies={caseStudies} />
      <hr />
      <Testimonials testimonials={testimonials} />
      <hr />
      <Section>
        <Container className="has-text-centered">
          <ArrowLink to="/work/workforce-productivity" className="is-info">
            Next: Mobile Workforces
          </ArrowLink>
        </Container>
      </Section>
    </Layout>
  )
}

WorkCaseStudies.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WorkCaseStudies
