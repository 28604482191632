import PropTypes from 'prop-types'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Card, Section, Columns, Container } from 'react-bulma-components'
import { Link } from 'gatsby'

// Components
import CaseStudyCard from 'components/case-study-card'

function AllWork({ caseStudies }) {
  const pickWidth = (i) => {
    // 0 => 0
    // 1 => 1
    // 2 => 2
    // 3 => 3
    // 4 => 0
    // 5 => 1
    const v = i + 1
    const x = v % 4
    switch (x) {
      case 1: {
        return 'is-7'
      }
      case 2: {
        return 'is-5'
      }
      case 3: {
        return 'is-5'
      }
      case 0: {
        return 'is-7'
      }
      default: {
        return 'is-12'
      }
    }
  }
  return (
    <Section className="is-small mt-6">
      <Container className="content is-widescreen">
        <Columns className="is-centered">
          {caseStudies.edges.map(({ node: caseStudy }) => (
            <Columns.Column
              className="is-5-widescreen is-6-desktop is-6-tablet is-12-mobile has-bg-alternating"
              key={caseStudy.slug}
            >
              <Card className="classy has-text-left">
                <span className="circle is-bottom-left" />
                <Columns>
                  <Columns.Column className="is-1">
                    <GatsbyImage
                      image={caseStudy.client.logoLight.gatsbyImageData}
                      alt={caseStudy.client.companyName}
                      className="is-slightly-opaque"
                    />
                  </Columns.Column>
                </Columns>

                {caseStudy.cardAppImage ? (
                  <GatsbyImage
                    image={caseStudy.cardAppImage.gatsbyImageData}
                    alt="image"
                    className="bottom-left resize"
                  />
                ) : (
                  <span />
                )}

                <h2 className="mt-0">{caseStudy.title}</h2>
                <Link
                  className="button is-text mb-6 pl-0 has-text-weight-semibold"
                  to={`/case-studies/${caseStudy.slug}`}
                >
                  View {caseStudy.client.companyName} Case Study →
                </Link>

                <Columns className="is-mobile">
                  <Columns.Column className="is-6-desktop" />
                  <Columns.Column className="is-6-desktop pb-6 mb-6">
                    {caseStudy.outcomes.map((outcome) => (
                      // eslint-disable-next-line react/jsx-key
                      <div>
                        <p>
                          <div className="is-size-1 is-size-2-mobile is-size-2-tablet has-text-weight-semibold mt-6">
                            {outcome.outcomeValue}
                          </div>
                          <div className="is-size-5-widescreen is-size-7-desktop is-size-7-tablet has-text-weight-semibold">
                            {outcome.summary}
                          </div>
                        </p>
                      </div>
                    ))}
                  </Columns.Column>
                </Columns>
              </Card>
            </Columns.Column>
          ))}
        </Columns>
      </Container>
    </Section>
  )
}
AllWork.propTypes = {
  caseStudies: PropTypes.object,
}
export default AllWork
